import { useMemo } from "react";

import { useSmartrrVendorSelector } from "@vendor-app/app/_state/typedVendorReduxHooks";

export function useCurrentOrganization() {
  const { organizations, activeOrganizationId } = useSmartrrVendorSelector(state => state.vendorOrganizations);

  return useMemo(
    () => organizations.find(org => (activeOrganizationId ? activeOrganizationId === org.id : !!org))!,
    [activeOrganizationId, organizations]
  );
}
